import React, { useState, useEffect } from "react";
import './App.css';
import { ethers } from "ethers";
import { useEthers } from '@usedapp/core'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config = require('./config.json');
const configStatus = config.status;
const configPrice = config.price;
const scanUrl = config[configStatus].scanUrl;
const opensea = config[configStatus].openseaCollection || "";
let contract_mint_address = config[configStatus].contract_mint_address || "";
let contract, provider;

if(window && window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
}

const BASC = require("./artifacts/contracts/BASC.sol/BASC.json");

if(provider) {
  const signer = provider.getSigner();
  contract = new ethers.Contract(contract_mint_address, BASC.abi, signer);
}

function App() {
  const { activateBrowserWallet, account } = useEthers();
  const [quantity, setQuantity] = useState(1);
  const [totalMint, setTotalMint] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);

  useEffect( () => {
    document.title = "Bored Alien Space Club"
    getTotal();
    getSupply();
    mintEvent();
  }, []);

  const truncate = (source, size) => {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  const mint = async () => {
    const price = (configPrice * quantity).toString();
    let overrides = {
      value: ethers.utils.parseEther(price)
    };
    
    try {
      /* const [account] = await window.ethereum.request({ method: "eth_requestAccounts" }); */
      if(account) {
        const mint = await contract.mint(account, quantity, overrides);
        console.log('mint', mint);
        toast.success('Success Mint!', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      } else {
        toast.error('No account', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch(e) {
      toast.error('Error mint', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const mintEvent = async () => {
    try {
      contract.on("Mint", (id) => {
        getTotal();
      });
    } catch(e) {
      toast.error('Error get mint', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const getTotal = async () => {
    try {
      const total = await contract.totalMint();
      setTotalMint(total.toNumber());
    } catch(e) {
      toast.error('Error get Total', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const getSupply = async () => {
    try {
      const supply = await contract.getMaxSupply();
      setMaxSupply(supply.toNumber());
    } catch(e) {
      toast.error('Error get Supply', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const changeQuantity = (type) => {
    let q = quantity;
    
    if(type === 1) {
      q++;

      if(q <= 5) {
        setQuantity(q);
      } else {
        setQuantity(5);
      }
    } else if(type === 2) {
      q--;
      
      if(q >= 1) {
        setQuantity(q);
      } else {
        setQuantity(1);
      }
    }
  }

  return (
    <React.Fragment>
      <div className='home_container'>
        <div className='home_background'>
          <a className='home_logo' href="/">
            <img className='logo_image' src='logo.png' alt='logo' />
          </a>
          <div className='home_row'>
            <div className='row_gif'>
              <img className='gif_one' src='example.gif' alt='gif1' />
            </div>
            <div className='row_separator'></div>
            <div className='row_card'>
              { provider && (
                <p className='card_count'>
                  { totalMint } / { maxSupply }
                </p>
              )}
              <p className='card_address'>
                <a className='address_link' target='_blank' rel='noreferrer' href={ `${scanUrl}/address/${contract_mint_address}` } >
                  { truncate(contract_mint_address, 15) }
                </a>
              </p>
              <span className='card_buttons'>
                <a className='card_button' target='_blank' rel='noreferrer' href="/">Roadmap</a>
                <a className='card_button' target='_blank' rel='noreferrer' href={opensea}>Opensea</a>
              </span>
              <div className='card_separator'></div>
              <p className='card_cost'>
                1 BASCNFT costs { configPrice } ETH
              </p>
              <div className='card_separator'></div>
              <p className='card_text'>
                Excluding gas fees.
              </p>
              <div className='card_separator'></div>
              { account && provider && (
                <React.Fragment>
                  <p className='card_text'>
                    Click buy to mint your NFT.
                  </p>
                  <div className='card_separator'></div>
                  <div className='card_counters'>
                    <button className='counters_button' onClick={ () => { changeQuantity(2) }}>
                      -
                    </button>
                    <div className='card_separator'></div>
                    <p className='counters_number'>
                      { quantity }
                    </p>
                    <div className='card_separator'></div>
                    <button className='counters_button' onClick={ () => { changeQuantity(1) }}>
                      +
                    </button>
                  </div>
                  <div className='card_separator'></div>
                  <div className='card_buy'>
                    <button className='buy_button' onClick={ mint }>
                      BUY
                    </button>
                  </div>
                </React.Fragment>
              ) }
              { !account && provider && (
                <React.Fragment>
                  <div className='card_connect'>
                    <p className="connect_text">
                      Connect to the Ethereum network
                    </p>
                    <div className='card_separator'></div>
                    <button className="connect_button" onClick={ activateBrowserWallet }>
                      CONNECT
                    </button>
                  </div>
                </React.Fragment>
              )}
              { !account && !provider && (
                <React.Fragment>
                  <div className='card_connect'>
                    <p className="connect_text" style={{ color: '#ff1dec' }}>
                      Install Metamask
                    </p>
                  </div>
                </React.Fragment>
              )}
              <div className='card_separator'></div>
            </div>
            <div className='row_separator'></div>
            <div className='row_gif'>
              <img className='gif_one' src='example.gif' alt='gif2' />
            </div>
          </div>
          <div className='home_footer'>
            <p className='footer_text'>
              Please make sure you are connected to the right network (Ethereum Mainnet) and the correct address. Please note: Once you make the purchase, you cannot undo this action.
            </p>
            <div className='footer_separator'></div>
            <p className='footer_text'>
              We have set the gas limit to 285000 for the contract to successfully mint your NFT. We recommend that you don't lower the gas limit.
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
